import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Base64 } from 'js-base64';

const Footer = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const servicesUrl = "https://getjob.stechomeyazilim.info:5101/getAboutEzgiNew/select/";
  const [getData, setData] = useState({
    ID:null,
							Company_Adress : null,
							Company_TelephoneNumber:null,
							Company_WebAdress: null,
							Company_MailAdress: null,
							Company_About: null,
							About_KVKK: null,
  });

  useEffect(() => {

		async function getContact () {
				try {      
		
			await axios.get(servicesUrl)
			  .then(res => {

					res.data.map((item) => {
						setData({
							...getData,
							ID:item.ID,
							Company_Adress :  item.Company_Adress,
							Company_TelephoneNumber:item.Company_TelephoneNumber,
							Company_WebAdress: item.Company_WebAdress,
							Company_MailAdress: item.Company_MailAdress,
							Company_About: item.Company_About,
							About_KVKK: item.About_KVKK,
						})
	
					  })
			  })


			  

			  
				} catch (error) {
				  console.log("error" + error)
				}
			  }


		getContact();
		
	
	}, [])

  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">
                  <div>
                    <ul className="footer-style">
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                           {getData.Company_Adress}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>{getData.Company_TelephoneNumber}</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>{getData.Company_MailAdress}</a>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-6 p-0">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.1879218490153!2d32.805943776144574!3d39.91481057152476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34fa951021391%3A0xe898d6e0fafeb745!2sDijitalFeat!5e0!3m2!1str!2str!4v1712904359339!5m2!1str!2str"
                  allowFullScreen
                  className="md-height"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>2024 Stechome</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;
