import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ReactHtml from 'raw-html-react';
import axios from 'axios';
import { Base64 } from 'js-base64';

const About = () => {
  const history = useHistory();

  const servicesUrl = "https://getjob.stechomeyazilim.info:5101/getAboutEzgiNew/select/";
  const [getData, setData] = useState({
    ID:null,
							Company_Adress : null,
							Company_TelephoneNumber:null,
							Company_WebAdress: null,
							Company_MailAdress: null,
							Company_About: null,
							About_KVKK: null,
  });


  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);

  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
  });

  const color= localStorage.getItem("color");
  useEffect(()=>{
  
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`
  })
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`
  })
  },[color]);
 
  useEffect(() => {

		async function getContact () {
				try {      
		
			await axios.get(servicesUrl)
			  .then(res => {

					res.data.map((item) => {
						setData({
							...getData,
							ID:item.ID,
							Company_Adress :  item.Company_Adress,
							Company_TelephoneNumber:item.Company_TelephoneNumber,
							Company_WebAdress: item.Company_WebAdress,
							Company_MailAdress: item.Company_MailAdress,
							Company_About: item.Company_About,
							About_KVKK: item.About_KVKK,
						})
	
					  })
			  })


			  

			  
				} catch (error) {
				  console.log("error" + error)
				}
			  }


		getContact();
		
	
	}, [])

  return (
    <section id="about" className="about" style={bgImg}>
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-5">
            <div className="about-contain">
              <div>
                <h2 className="title">
                  <span>Kuryesen</span> Hakkında
                </h2>
               { getData.Company_About != null ? <p className="caption-about">
                <ReactHtml html={Base64.decode(getData.Company_About.substring(0,695))}/>
                </p> : null}
                <div className="row sm-mb">
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon1.png`} alt="easy-to-customized" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Kullanıcı Dostu</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon3.png`} alt="easy-to-use" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Kolay Erişim</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon2.png`} alt="Awasome-Design" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Tasarım Dostu</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon4.png`} alt="SEO-Friendly" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Kurye Dostu</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
